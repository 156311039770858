var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"5"}}),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",class:{ red_border: _vm.notEmpty },attrs:{"placeholder":"Search..."},on:{"keyup":function($event){return _vm.checkString($event)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('b-col',{attrs:{"cols":"12","md":"1"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.masterSearch()}}},[_vm._v(" Filter ")])])])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"responsive":"","primary-key":"id","items":_vm.listClients,"empty-text":"No patients found","fields":_vm.fields,"filter":_vm.search,"per-page":0,"id":"my-table","current-page":_vm.currentPage,"show-empty":""},scopedSlots:_vm._u([{key:"cell(SettingValue)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + item.SettingValue)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL,"transition":"scale-transition","offset-y":true},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true},{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","ripple":""}},'b-btn',attrs,false),on))]}}],null,true)},[_c('b-list-group',{staticStyle:{"padding":"2px","margin-bottom":"2px"},attrs:{"dense":"","rounded":""}},[_c('router-link',{staticClass:"urlPagina",attrs:{"to":{
                name: 'form-prices-selfpaids',
                params: { id: item.id, item: item },
              }}},[_c('b-list-group-item',{staticClass:"urlPagina",staticStyle:{"padding":"0"},attrs:{"ripple":false}},[_c('b-list-group-item',{staticClass:"font-weight-bold",staticStyle:{"border":"none","padding":"5px"}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_vm._v(" Details ")],1)],1)],1)],1)],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.pagination.from)+" to "+_vm._s(_vm.pagination.to)+" of "+_vm._s(_vm.totalItems)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pagination_block"},[_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item",class:{ disabled: _vm.pagination.prev_page_url == null }},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.getClientes(_vm.pagination.first_page_url)}}},[_vm._v(" « ")])]),_c('li',{staticClass:"page-item",class:{ disabled: _vm.pagination.prev_page_url == null }},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.getClientes(_vm.pagination.prev_page_url)}}},[_vm._v(" Previous ")])]),(_vm.currentPage - 2 > 0)?_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){_vm.getClientes(
                        _vm.pagination.path + '?page=' + (_vm.currentPage - 2)
                      )}}},[_vm._v(" "+_vm._s(_vm.currentPage - 2)+" ")])]):_vm._e(),(_vm.pagination.prev_page_url)?_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.getClientes(_vm.pagination.prev_page_url)}}},[_vm._v(" "+_vm._s(_vm.currentPage - 1)+" ")])]):_vm._e(),_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",class:{
                      current: _vm.pagination.current_page == _vm.currentPage,
                    },attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.currentPage)+" ")])]),(_vm.pagination.next_page_url)?_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.getClientes(_vm.pagination.next_page_url)}}},[_vm._v(" "+_vm._s(_vm.currentPage + 1)+" ")])]):_vm._e(),(_vm.currentPage + 2 <= _vm.lastPage)?_c('li',{staticClass:"page-item"},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){_vm.getClientes(
                        _vm.pagination.path + '?page=' + (_vm.currentPage + 2)
                      )}}},[_vm._v(" "+_vm._s(_vm.currentPage + 2)+" ")])]):_vm._e(),_c('li',{staticClass:"page-item",class:{ disabled: _vm.pagination.next_page_url == null }},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.getClientes(_vm.pagination.next_page_url)}}},[_vm._v(" Next ")])]),_c('li',{staticClass:"page-item",class:{ disabled: _vm.pagination.next_page_url == null }},[_c('button',{staticClass:"page-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.getClientes(_vm.pagination.last_page_url)}}},[_vm._v(" » ")])])])])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }